<template>
  <div>
    <input type='file' ref='fileInput' class='hidden-sm-and-up hidden-sm-and-down' accept='.json' @change='readImportedFile'/>
        <v-card
          class="pa-3"
          width="100%"
        >
          <v-card-title>
            FAQ Library
            <v-spacer></v-spacer>
            <v-dialog
              v-model="newEntryDialog"
              width="500px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                    outlined
                    color="primary"
                    small
                    @click="exportCurrentFAQ"
                    class="ml-2"
                >
                  Export
                </v-btn>
                <v-btn
                    class='ml-2'
                    outlined
                    color="primary"
                    small
                    @click="triggerImport"
                >
                  Import
                </v-btn>
                <v-btn
                  small
                  outlined
                  color="primary"
                  v-on="on"
                >
                  Add Entry
                </v-btn>

              </template>
              <v-card class="pa-3">
                <v-card-title>
                  New FAQ Entry
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="defaultEntry.title[currentLocale]"
                    label="Title"
                    hint="FAQ Entry Title"
                    persistent-hint
                  >
                  </v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    small
                    outlined
                    color="primary"
                    @click="addEntry"
                  >
                    Add
                  </v-btn>
                  <v-btn
                    small
                    outlined
                    color="primary"
                    @click="cancelAddEntry"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-card-text>
            <v-col cols=2>
              <v-select
                v-model="currentLocale"
                label="Languages"
                item-value="code"
                item-text="name"
                hint="Language to modify"
                persistent-hint
                dense
                :items="languages"
              >
              </v-select>
            </v-col>
            <v-expansion-panels accordion hover>
            <v-expansion-panel
              v-for="(item, index) in localFaqEntries"
              :key="index"
            >
              <v-expansion-panel-header>
                {{ item.title[currentLocale] }}
                <template v-slot:actions>
                  <v-icon small color="primary" @click.stop="translateEntry(index)">translate</v-icon>
                  <v-icon small color="red" @click.stop="removeEntry(index)">delete</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols=4 class="pa-0">
                  <v-text-field
                    v-model="item.title[currentLocale]"
                    label="Title"
                    hint="FAQ Entry Title"
                    persistent-hint
                  >
                  </v-text-field>
                </v-col>
                <v-textarea
                  v-model="item.text[currentLocale]"
                  outlined
                >
                </v-textarea>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              outlined
              class="mb-2"
              color="primary"
              @click="save"
            >
              Save
            </v-btn>
            <v-btn
              v-if="showcancel"
              small
              class="mb-2"
              outlined
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-dialog v-model="translationDialog">
          <v-card>
            <v-card-title>
              FAQ Translation
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-col cols=2 class="px-0">
                    <v-select
                      v-model="currentLocale"
                      label="Languages"
                      item-value="code"
                      item-text="name"
                      hint="Language to modify"
                      persistent-hint
                      dense
                      :items="languages"
                      disabled
                    >
                    </v-select>
                  </v-col>
                  <v-text-field
                    v-model="currentTitle"
                    label="Title"
                    readonly
                    hint="FAQ Entry Title"
                    persistent-hint
                  >
                  </v-text-field>
                  <v-textarea
                    v-model="currentText"
                    readonly
                    outlined
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-col cols=2 class="px-0">
                    <v-select
                      v-model="translationLocale"
                      label="Languages"
                      item-value="code"
                      item-text="name"
                      hint="Language to modify"
                      persistent-hint
                      dense
                      :items="languages"
                    >
                    </v-select>
                  </v-col>
                  <v-text-field
                    v-model="translationTitle"
                    label="Title"
                    hint="FAQ Entry Title"
                    persistent-hint
                  >
                  </v-text-field>
                  <v-textarea
                    v-model="translationText"
                    outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                small
                outlined
                class="mb-2"
                color="primary"
                @click="saveTranslation"
              >
                Ok
              </v-btn>
              <v-btn
                small
                class="mb-2"
                outlined
                color="primary"
                @click="cancelTranslation"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  data: () => ({
    newEntryDialog: false,
    currentLocale: 'en',
    localFaqEntries: {},

    translationDialog: false,
    currentIndex: null,
    currentTitle: null,
    currentText: null,
    translationTitle: null,
    translationText: null,
    translationLocale: null,

    defaultEntry: { title: {}, text: {} },
  }),
  props: [ 'showcancel', 'locale', 'faqentries', 'languages' ],
  methods: {
    addEntry() {
      this.localFaqEntries.push(this.defaultEntry);
      this.cancelAddEntry();
    },
    removeEntry(index) {
      this.localFaqEntries.splice(index, 1);
    },
    cancelAddEntry() {
      this.defaultEntry = { title: {}, text: {} };
      this.newEntryDialog = false;
    },
    translateEntry(index) {
      if(this.languages.length <= 1) {
        this.$bus.$emit('errorMessage', 'Cannot translate with only one language present');
        return;
      }

      for(let i = 0; i < this.languages.length; i++) {
        if(this.languages[i].code !== this.currentLocale) {
          this.translationLocale = this.languages[i].code;
        }
      }

      this.currentIndex = index;

      this.currentTitle = this.localFaqEntries[index].title[this.currentLocale];
      this.currentText = this.localFaqEntries[index].text[this.currentLocale];

      if(this.localFaqEntries[index].title[this.translationLocale])
        this.translationTitle = this.localFaqEntries[index].title[this.translationLocale];

      if(this.localFaqEntries[index].text[this.translationLocale])
        this.translationText = this.localFaqEntries[index].text[this.translationLocale];

      this.translationDialog = true;
    },
    async save() {
      this.$emit('save', this.localFaqEntries);
    },
    async cancel() {
      this.$emit('cancel');
    },
    async saveTranslation() {
      this.localFaqEntries[this.currentIndex].title[this.translationLocale] = this.translationTitle;
      this.localFaqEntries[this.currentIndex].text[this.translationLocale] = this.translationText;

      this.cancelTranslation();
    },
    async cancelTranslation() {
      this.translationDialog = false;
      this.currentIndex = null;
      this.currentTitle = null;
      this.currentText = null;
      this.translationTitle = null;
      this.translationText = null;
      this.translationLocale = null;
    },
    exportCurrentFAQ() {
      let itemData = [ ...this.localFaqEntries ];
      const data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(itemData, null, 4));
      let lnk = document.createElement('a');
      lnk.setAttribute("href", data);
      lnk.setAttribute("download", `All_Languages_FAQ.json`);
      lnk.click();
      lnk.remove();
    },
    triggerImport() {
      this.$refs.fileInput.click();
    },
    readImportedFile(e) {
      const files = e.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        try {
          const faqEntries = JSON.parse(fileReader.result);
          this.localFaqEntries = JSON.parse(JSON.stringify(faqEntries));
        } catch(err) {
          this.$bus.$emit('errorMessage', err.message);
        }
      })
      fileReader.readAsText(files[0])
      this.image = files[0]
    }
  },
  watch: {
    faqlibrary(n, o) {
      this.localFaqEntries = JSON.parse(JSON.stringify(this.faqentries));
      this.currentLocale = this.locale;
    }
  },
  async mounted() {
    this.localFaqEntries = JSON.parse(JSON.stringify(this.faqentries));
    this.currentLocale = this.locale;
  }
}
</script>

<style scoped>

</style>
