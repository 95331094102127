<template>
  <div style="overflow: hidden">
    <v-row class="white pa-0 ma-0" style="height: 90vh">
      <v-col cols=3 class="pl-1 pr-0 py-0 ma-0 mt-1">
        <v-expand-transition>
          <v-sheet v-if="columnActive(selectedColumn)" class="pa-5">
            <v-tabs>
              <v-tab>
                General
              </v-tab>
              <v-tab>
                Translations
              </v-tab>
              <v-tab v-if="['singleChoice','multipleChoice'].includes(currentLayout.fields[selectedField].type)">
                Choices
              </v-tab>
              <v-tab-item>
                <v-text-field
                  class="mt-5"
                  v-model="currentLayout.fields[selectedField].displayNames[defaultLocale]"
                  :label="`${defaultLanguage.name} display name`"
                  :hint="`${defaultLanguage.name} display name (default)`"
                  persistent-hint
                >
                </v-text-field>
                <v-text-field
                  class="mt-5"
                  v-model="currentLayout.fields[selectedField].hints[defaultLocale]"
                  :label="`${defaultLanguage.name} Hint`"
                  :hint="`${defaultLanguage.name} hint text`"
                  persistent-hint
                >
                </v-text-field>
                <v-switch
                  v-if="currentLayout.fields[selectedField] !== 'yesNo'"
                  :label="currentLayout.fields[selectedField].required ? 'Field required' : 'Field not required'"
                  v-model="currentLayout.fields[selectedField].required"
                ></v-switch>
                <v-radio-group
                  dense
                  v-if="!currentLayout.fields[selectedField].fullWidth"
                  v-model="currentLayout.fields[selectedField].width"
                  label="Field Width"
                >
                  <v-radio
                    v-for="n in [4, 6, 12]"
                    :key="n"
                    :label="`${n}`"
                    :value="n"
                  ></v-radio>
                </v-radio-group>
                <v-radio-group
                  dense
                  v-if="currentLayout.fields[selectedField].type === 'singleChoice'"
                  v-model="currentLayout.fields[selectedField].choiceDisplayType"
                  label="Choice Display Type"
                  @change="currentLayout.fields[selectedField].initialize()"
                >
                  <v-radio
                    label="Radio Buttons"
                    type="number"
                    :value="0"
                  >
                  </v-radio>
                  <v-radio
                    label="Drop Down"
                    :value="1"
                  >
                  </v-radio>
                </v-radio-group>
                <v-slider
                  v-if="currentLayout.fields[selectedField].type === 'longText'"
                  label="Rows"
                  v-model="currentLayout.fields[selectedField].rows"
                  :value="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                  :tick-labels="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                  min="1"
                  max="10"
                  ticks="always"
                  tick-size="4"
                >
                </v-slider>
              </v-tab-item>
              <v-tab-item>
                <v-text-field
                  v-for="locale in secondaryLocales"
                  class="mt-5"
                  v-model="currentLayout.fields[selectedField].displayNames[locale]"
                  :label="`${availableLanguagesMap[locale].name} Field Name`"
                  :hint="`${availableLanguagesMap[locale].name} Display Name`"
                  :key="locale"
                  persistent-hint
                >
                </v-text-field>
                <v-text-field
                  v-for="locale in secondaryLocales"
                  class="mt-5"
                  v-model="currentLayout.fields[selectedField].hints[locale]"
                  :label="`${availableLanguagesMap[locale].name} Field Hint`"
                  :hint="`${availableLanguagesMap[locale].name} Hint`"
                  :key="locale"
                  persistent-hint
                >
                </v-text-field>
              </v-tab-item>
              <v-tab-item v-if="['singleChoice','multipleChoice'].includes(currentLayout.fields[selectedField].type)">
                      <v-dialog v-model="editChoiceDialog" width="unset" max-width="500px">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            color="primary"
                            small
                            outlined
                            class="mt-5 mt-2"
                            v-on="on"
                          >
                            New Choice
                          </v-btn>
                        </template>
                        <v-card class="pa-3">
                          <v-card-title>
                            <span class="headline">{{ choiceFormTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-form ref="choiceForm">
                              <v-text-field
                                v-for="locale in [defaultLocale, ...secondaryLocales]"
                                :label="`${availableLanguagesMap[locale].name} Choice`"
                                :hint="`Enter ${availableLanguagesMap[locale].name} Choice Text`"
                                :key="locale"
                                v-model="editedChoice[locale]"
                              >
                              </v-text-field>
                            </v-form>
                          </v-card-text>

                          <v-card-actions>
                            <v-btn
                              color="primary"
                              small
                              outlined
                              @click="saveChoice"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="primary"
                              small
                              outlined
                              @click="closeEditChoiceDialog"
                            >
                              Cancel
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                <v-list>
                  <v-list-item
                    ref="field"
                    v-for="(choice, index) in currentLayout.fields[selectedField].choices"
                    :key="index"
                    :id="index"
                    draggable="true"
                    class="choice"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                      {{ choice[defaultLocale] }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Choice
                      </v-list-item-subtitle>
                    </v-list-item-content>
                        <v-list-item-action>
                          <v-row>
                            <v-btn icon small @click="editChoice(index)">
                              <v-icon small color="primary">edit</v-icon>
                            </v-btn>
                            <v-btn icon small @click="deleteChoice(index)">
                              <v-icon small color="red">delete</v-icon>
                            </v-btn>
                          </v-row>
                        </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-tab-item>
            </v-tabs>
          </v-sheet>
        </v-expand-transition>
      </v-col>
      <v-col cols=7 class="pl-1 pr-0 py-0 ma-0 main-widget">
        <v-sheet class="pa-5">
          <v-row align="center">
            <v-col cols=4>
              Layout Editor - {{ currentLayout.name }} - {{ columnActive(selectedField) }}
            </v-col>
            <v-col cols=4>
              <v-btn
                small
                class="mr-1"
                color="primary"
                outlined
                @click="saveAndClose"
              >
                Save and Close
              </v-btn>
              <v-btn
                small
                class="mr-1"
                color="primary"
                outlined
                @click="save"
              >
                Save
              </v-btn>
              <v-btn
                small
                color="primary"
                outlined
                @click="close"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col cols=4 class="text-right">
              <v-btn
                small
                outlined
                color="primary"
                @click="addColumn()"
              >
                Add Column
              </v-btn>
            </v-col>
          </v-row>
          <v-divider/>
          <v-sheet class="px-3 ma-0 scroll">
            <v-list height="80vh">
              <v-list-item-group
                active-class="active"
                v-model="selectedColumn"
                @change="checkSelection"
              >
                <v-row justify="center">
                  <v-col
                    v-for="(col, index) in currentLayout.columns"
                    class="front mb-0 py-1 px-0"
                    :key="index" 
                    :cols="getColumnWidth(col)"
                  >
                    <v-list-item
                      ref="column"
                      :id="index"
                      class="mr-1 py-1 destination"
                      color="rgba(0,0,0,1)"
                      dense
                    >
                      <v-list-item-avatar
                        ref="dragHandle" 
                        :id="index" 
                        class="front" 
                        draggable="true"
                        v-if="columnActive(index)"
                        @dragstart="handleDragStart"
                        @dragend="handleDragEnd"
                      >
                        <v-icon color="primary">drag_indicator</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content draggable="true" class="back" v-if="columnActive(index)">
                        <v-list-item-title>
                          <p class="body-2">
                            {{ getFieldHint(col) }}
                          </p>
                          <component
                            dense
                            v-bind:is="currentLayout.fields[col].component"
                            :label="getFieldLabel(col)"
                            :outlined="currentLayout.fields[col].outlined"
                            :type="currentLayout.fields[col].componentType"
                            :rows="getFieldRows(col)"
                            :items="currentLayout.fields[col].choices"
                            item-value="en"
                            item-text="en"
                          >
                            <v-radio
                              v-if="currentLayout.fields[col].component === 'v-radio-group'"
                              v-for="(choice, index) in currentLayout.fields[col].choices"
                              :key="index"
                              :label="choice[defaultLocale]"
                            >
                            </v-radio>
                            <v-subheader v-if="currentLayout.fields[col].type === 'multipleChoice'">
                              {{ getFieldLabel(col) }}
                            </v-subheader>
                            <v-checkbox
                              v-if="currentLayout.fields[col].type === 'multipleChoice'"
                              v-for="(choice, index) in currentLayout.fields[col].choices"
                              class="ma-0 pa-0"
                              :hide-details="index !== (currentLayout.fields[col].choices.length - 1)"
                              multiple
                              :key="index"
                              :label="choice[defaultLocale]"
                            >
                            </v-checkbox>
                          </component>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content class="back" v-if="columnActive(index) === false">
                        <v-list-item-title align="center">
                          Drag element here
                        </v-list-item-title>
                      </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon @click="remove(index)">
                              <v-icon small color="primary">clear</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                  </v-col>
                </v-row>
              </v-list-item-group>
            </v-list>
          </v-sheet>
        </v-sheet>
      </v-col>
      <v-col cols=2 class="pl-1 pr-0 py-0 ma-0">
        <v-sheet class="pa-5">
          <v-row align="center">
            <v-col cols=6>
              Field List
            </v-col>
            <v-col cols=6 class="text-right">
              <v-btn
                small
                outlined
                color="primary"
                @click="newFieldDialog = !newFieldDialog"
              >
                Add Field
              </v-btn>
            </v-col>
          </v-row>
            <v-list>
              <v-list-item
                ref="field"
                v-for="(field, index) in currentLayout.fields"
                :key="index"
                :id="index"
                draggable="true"
                class="source"
                v-if="availableFields.includes(field)"
              >
                <v-list-item-avatar>
                  <v-icon>drag_indicator</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ field.displayNames[defaultLocale] }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getFieldTypeName(field.type) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="deleteField(index)">
                        <v-icon small color="red">delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="newFieldDialog" width="unset">
      <v-card>
        <v-card-title>
          Add New Field
        </v-card-title>
        <v-card-text>
          <v-form ref="newFieldForm">
            <v-text-field
              v-model="newFieldName"
              label = "Field Name"
              :rules = "fieldNameRules"
            >
            </v-text-field>
            <v-autocomplete
              v-model = "newFieldType"
              :items = "fieldTypes"
              item-text = "name"
              item-value= "type"
              :rules = "fieldTypeRules"
            >
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            small
            outlined
            color="primary"
            @click="saveNewField"
          >
            Save
          </v-btn>
          <v-btn
            small
            outlined
            color="primary"
            @click="cancelNewField"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { Layout, Field } from '@/assets/layout'
import { VDatePicker, VTextField, VTextarea, VSwitch, VRadioGroup, VRadio, VItemGroup, VSelect } from 'vuetify/lib'

export default {
    components: {
        VTextField,
        VTextarea,
        VSwitch,
        VDatePicker,
        VRadioGroup,
        VRadio,
        VItemGroup,
        VSelect,
    },
    data: () => ({ 
      cols: [],
      fields: [],

      editChoiceDialog: false,
      editedChoice: {},
      editedChoiceIndex: -1,

      newFieldDialog: false,
      newFieldName: '',
      newFieldType: 'text',

      defaultFieldType: 'text',

      fieldTypeRules: [
        v => !!v || 'Please select a field type',
      ],

      currentLayout: { fields: []},
      selectedColumn: null,
      selectedField: null,
    }),
  props: {
    layout: Object,
  },
  computed: {
    ...mapState([
      'configuration',
    ]),
    ...mapGetters([
      'availableLanguages',
      'availableLanguagesMap',
    ]),
    availableFields() {
      return this.currentLayout.fields.filter((x, index) => this.currentLayout.columns.includes(index) === false);
    },
    defaultLocale() {
      return this.configuration.defaultLanguage.code;
    },
    secondaryLocales() {
      let secondary = [];

      for(let i = 0; i < this.availableLanguages.length; i++) {
        if(this.availableLanguages[i].code != this.configuration.defaultLanguage.code)
          secondary.push(this.availableLanguages[i].code);
      }
      
      return secondary;
    },
    defaultLanguage() {
      return this.configuration.defaultLanguage;
    },
    fieldTypes() {
      return Field.getFieldTypes();
    },
    fieldNameRules() {
      return [
        v => !!v || 'Field name required',
        v => /^[A-Z ]+$/i.test(v) || 'Invalid characters',
        v => {
            if(typeof(v) === undefined || v == '')
              return true;

            if(this.currentLayout.fields.find(x => x.name === Field.createFieldName(v)) === undefined)
              return true;

            return 'Field already exists';
          }
        ];
    },
    fieldDetailsTitle() {
      let field = this.currentLayout.fields[this.selectedField];

      if(field === null || typeof(field) === 'undefined')
        return "Field Details";
      
      return `Field Details - ${field.displayName}`;
    },
    choiceFormTitle() {
      return this.editedChoiceIndex === -1 ? `New Choice` : `Edit Choice`;
    }
  },
  methods: {
      ...mapActions([
        'setLoading',
      ]),
      ...mapActions('admin', [
        'updateLayout',
      ]),
    validateFields() {
      // Iterate all actively used fields
      // Check that each field contains translation for each language
      // Iterate all choices and do the same
      // Navigate to tab where something is missing
    },
    saveNewField() {
      if(this.$refs.newFieldForm.validate()) {
        let f = new Field(this.newFieldName, this.newFieldType, this.defaultLocale, 6, false);

        this.currentLayout.fields.push(f);

        this.$nextTick(() => {
          this.refreshColumnEventListeners();
          this.refreshFieldEventListeners();
        });

        this.cancelNewField();
      }
    },
    cancelNewField() {
      this.newFieldDialog = false;
      this.newFieldType = this.defaultFieldType;
      this.newFieldName = '';

      this.$nextTick(() => {
        this.$refs.newFieldForm.resetValidation();
        
      });
    },
    columnActive(index) {
      let item = this.currentLayout.columns[index];

      if(item === null || typeof(item) === 'undefined'){
        return false;
      }

      return true;
    },
    getColumnWidth(fieldIndex) {
      let field = this.currentLayout.fields[fieldIndex];

      if(field === null || typeof(field) === 'undefined')
        return 6;

      if(field.fullWidth === true)
        return 12;

      return field.width;
    },
    getFieldDisplayName(fieldIndex) {
      let field = this.currentLayout.fields[fieldIndex];

      return field.displayNames[this.defaultLocale];  
    },
    getFieldLabel(fieldIndex) {
      let field = this.currentLayout.fields[fieldIndex];

      if(field.required === true)
        return `${field.displayNames[this.defaultLocale]}*`;
      else
        return field.displayNames[this.defaultLocale];
    },
    getFieldHint(fieldIndex) {
      let field = this.currentLayout.fields[fieldIndex];

      return field.hints[this.defaultLocale];     
    },
    getFieldRows(fieldIndex) {
      let field = this.currentLayout.fields[fieldIndex];

      if(field === null || typeof(field) === 'undefined')
        return 0;

      return field.rows;
    },
    getFieldTypeName(type) {
      return Field.getTypeName(type);
    },
    fieldHintSet(fieldIndex) {
      let field = this.currentLayout.fields[fieldIndex];
      let hint = field.hints[this.defaultLocale];

      if(hint === null || typeof(hint) === 'undefined' || hint.length === 0)
        return false;
      else
        return true;  
    },
    deleteField(fieldIndex) {
      this.currentLayout.fields.splice(fieldIndex, 1);
      
      // Adjust the column to field mapping after removing the field
      for(let i = 0; i < this.currentLayout.columns.length; i++)
      {
        if(this.currentLayout.columns[i] > fieldIndex)
          this.currentLayout.columns[i] -= 1;
      }
    },
    remove(index) {
      // There's no element at this position, so we remove the column
      if(typeof(this.currentLayout.columns[index]) === 'undefined' || this.currentLayout.columns[index] === null)
      {
        this.currentLayout.columns.splice(index, 1);
        return;
      }

      // Clear the field, set to null
      this.$set(this.currentLayout.columns, index, null);

      this.$nextTick(() => {
        this.refreshColumnEventListeners();
        this.refreshFieldEventListeners();
      }); 
    },
    handleDragStart(e) {
      e.srcElement.style.opacity = '0.4';
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData("text", e.srcElement.id);
      e.dataTransfer.setData("fromList", e.srcElement.classList.contains("source"));
    },
    handleDragOver(e) {
      if (e.preventDefault) {
         // Necessary. Allows us to drop.
        e.preventDefault();
      }

        e.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.

        return false;
    },
    handleDragEnter(e) {
      if(typeof(e.target.classList) === 'undefined' || !e.target.classList.contains('destination'))
        return;

      // this / e.target is the current hover target.
      e.target.classList.add('over');
    },
    handleDragLeave(e) {
      if(typeof(e.target.classList) === 'undefined' || !e.target.classList.contains('destination'))
        return;
      // this / e.target is the current hover target.
      e.target.classList.remove('over');
    },
    handleDrop(e) {
      if (e.preventDefault) { e.preventDefault(); }
      if (e.stopPropagation) { e.stopPropagation(); }

      if(typeof(e.target.classList) === 'undefined' || !e.target.classList.contains('destination'))
        return;

      let fromList = (e.dataTransfer.getData('fromList') == 'true');
      let sourceId = parseInt(e.dataTransfer.getData('text'));

      let targetId = parseInt(e.target.id);

      if(fromList === true) {
        // If there's already a field present, remove it
        if(this.columnActive(targetId))
        {
          this.remove(targetId);
        }

        this.$set(this.currentLayout.columns, targetId, sourceId);
      } else {
        // Swap between columns
        let dst = this.currentLayout.columns[targetId];
        let src = this.currentLayout.columns[sourceId];

        this.$set(this.currentLayout.columns, targetId, src);
        this.$set(this.currentLayout.columns, sourceId, dst);
      }

      return false;
    },
    handleDragEnd(e) {
      let handles = this.$refs.dragHandle;

      [].forEach.call(handles, (handle) => {
        handle = handle.$el;
        handle.style.opacity = '1.0';
      });

      [].forEach.call(this.fields, (field) => {
        field = field.$el;
        field.style.opacity = '1.0';
      });

      [].forEach.call(this.cols, (col) => {
        col = col.$el;
        col.classList.remove('over');
      });
    },
    addColumn() {
      this.currentLayout.columns = [...this.currentLayout.columns, ...new Array(1)];

      this.$nextTick(() => {
        this.refreshColumnEventListeners(); 
      });     
    },
    refreshColumnEventListeners() {
      this.cols = this.$refs.column;

      if(!this.cols)
        return;

      [].forEach.call(this.cols, (col) => {
        col = col.$el;
        col.removeEventListener('dragstart', this.handleDragStart, false);
        col.removeEventListener('dragenter', this.handleDragEnter, false)
        col.removeEventListener('dragover', this.handleDragOver, false);
        col.removeEventListener('dragleave', this.handleDragLeave, false);
        col.removeEventListener('drop', this.handleDrop, false);
        col.removeEventListener('dragend', this.handleDragEnd, false);
      });

      [].forEach.call(this.cols, (col) => {
        col = col.$el;
        col.addEventListener('dragstart', this.handleDragStart, false);
        col.addEventListener('dragenter', this.handleDragEnter, false)
        col.addEventListener('dragover', this.handleDragOver, false);
        col.addEventListener('dragleave', this.handleDragLeave, false);
        col.addEventListener('drop', this.handleDrop, false);
        col.addEventListener('dragend', this.handleDragEnd, false);
      });
    },
    refreshFieldEventListeners() {
      this.fields = this.$refs.field;

      if(!this.fields)
        return;

      [].forEach.call(this.fields, (field) => {
        field = field.$el;
        field.removeEventListener('dragstart', this.handleDragStart, false);
        field.removeEventListener('dragend', this.handleDragEnd, false);
      });

      [].forEach.call(this.fields, (field) => {
        field = field.$el;
        field.addEventListener('dragstart', this.handleDragStart, false);
        field.addEventListener('dragend', this.handleDragEnd, false);
      });
    },
    clearSelection() {
      this.$nextTick(() => {
        this.selectedField = null;
      });
    },
    checkSelection() {
      if(this.selectedColumn === null)
        return;

      if(this.columnActive(this.selectedColumn) === false)
      {
        this.$nextTick(() => {this.selectedColumn = null;});
      } else {
        this.selectedField = this.currentLayout.columns[this.selectedColumn];
      }

    },
    close() {
      this.$emit('close');
    },
    async save() {
      try {
        const response = await this.updateLayout(this.currentLayout);
        const updated = { ...response.data.data };
        this.currentLayout = Layout.fromObject(updated);
        this.$bus.$emit('successMessage', 'Layout saved');
      } catch(error) {
        console.log(error);
        this.$bus.$emit('errorMessage', 'Could not update layout');
      } finally {
        this.setLoading(false);
      }
    },
    async saveAndClose() {
      try {
        await this.updateLayout(this.currentLayout);
        this.$bus.$emit('successMessage', 'Layout saved');
        this.close();
      } catch(error) {
        console.log(error);
        this.$bus.$emit('errorMessage', 'Could not update layout');
      } finally {
        this.setLoading(false);
      }
    },
    editChoice(index) {
          this.editedChoiceIndex = index;
          this.editedChoice = this.currentLayout.fields[this.selectedField].choices[index];
          this.editChoiceDialog = true;
    },
    deleteChoice(index) {
      this.currentLayout.fields[this.selectedField].choices.splice(index, 1);
    },
    closeEditChoiceDialog() {
      this.editChoiceDialog = false;
      setTimeout(() => {
        this.editedChoice = {};
        this.editedChoiceIndex = -1;
        this.$refs.choiceForm.resetValidation();
      }, 300);
      this.editChoiceDialog = false;
    },
    saveChoice() {
      if(this.editedChoiceIndex === -1)
        this.currentLayout.fields[this.selectedField].choices.push(this.editedChoice);

      this.closeEditChoiceDialog();
    },
  },
  created() {
    this.currentLayout = Object.assign(new Layout(''), this.layout).clone();
  },
  mounted() {
    this.refreshColumnEventListeners();
    this.refreshFieldEventListeners();
  }
}

</script>

<style scoped>
.main-widget {
  border-left: 2px dotted gray;
  border-right: 2px dotted gray;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}
/*
 * Destination list for dragged objects
 */
.destination {
  border: 1px dashed #000;
  height: 100%;
}

/*
 * Source list for dragged objects
 */
.source {
  border: 1px solid grey;
}

/*
 * Style when dragged over
 */
.over {
  border: 2px dashed #000;
}

/*
 * Active class of v-list-item
 */
.active {
  background-color: rgba(0, 105, 117, 0.1)
}

/*
 * Make the v-list-item be on top of
 * all child elements to have DnD behave
 * nicely
 */
.front {
  z-index: 2;
}

/*
 * Push child elements to the back for DnD
 */
.back {
  z-index: -1;
}

/*
 * Choice list
 */
.choice {
  border: 1px solid grey;
}

</style>