<template>
<div style="height: 100vh; background-color: #fff;">
  <v-app-bar
    fixed
    color="white"
    clipped-left
    flat
   >
    <v-row>
      <div class="header pt-4 pl-4">
        <img src="/img/logo_wbg.svg" class="header"/>
      </div>
      <v-spacer/>
      <v-btn icon large @click="close" class="mr-5">
        <v-icon large color="primary">close</v-icon>
      </v-btn>
    </v-row>
  </v-app-bar>
  <v-card tile flat style="position: relative; top: 50px;">
    <v-card-title>
      <v-col
        class="text-center"
        cols="10"
        offset="1"
        md="6"
        offset-md="3"


      >
      Impressum
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-col
        class="text-center"
        cols="10"
        offset="1"
        md="6"
        offset-md="3"
      >
        Julius Baer Group Ltd.<br/>
        Christoph Hiestand<br/>
        Group General Counsel<br/>
        Bahnhofstrasse 36<br/>
        CH-8001 Zurich<br/>
        Phone +41 (0) 58 888 111<br/>
        Fax +41 (0) 58 888 1122<br/>
        christoph.hiestand@juliusbaer.com<br/><br/>
        <p>
        Julius Baer Group Ltd. is a limited liability company incorporated under the laws of Switzerland. The company is registered in the Commercial Register of the Canton of Zurich/Switzerland under UID CHE-114.934.412 and its shares are listed on the SIX Swiss Stock Exchange.
        Julius Baer Group Ltd. is the parent company of the Julius Baer Group of companies and subject to consolidated supervision by the Swiss Financial Market Supervisory Authority FINMA.
        </p>
        © 2022 Julius Baer
      </v-col>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
export default {
  name: 'Impressum',
  data: () => ({
  }),
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

.logo {
  fill: #ffffff;
}

</style>
