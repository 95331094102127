import { render, staticRenderFns } from "./FaqConfiguration.vue?vue&type=template&id=5538072e&scoped=true&"
import script from "./FaqConfiguration.vue?vue&type=script&lang=js&"
export * from "./FaqConfiguration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5538072e",
  null
  
)

export default component.exports