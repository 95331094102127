<template>
  <div>
    <div :style="$vuetify.breakpoint.smAndDown ? 'height: 60vh;' : 'height: 58vh;'">
      <div v-if="(currentCase && currentCase._key)|| true" style="height: 53vh;">
        <v-row class="mb-3" justify="center">
          <span
            class="white--text font-weight-bold pt-5 canary-title"
          >{{ $t('titleCase') }} {{ caseTitle }}</span>
        </v-row>
        <div
          class="container pt-0 pb-0 px-0"
          :style="$vuetify.breakpoint.smAndDown ? 'width: 95vw' : 'width: 65vw'"
        >
          <div ref="messageHistory" class="message-history mt-0 pa-0 white">
            <div>
              <v-card
                v-for="(message, index) in messages"
                tile
                flat
                class="py-3 px-5"
                :color="message.userKey !== null ? '#f1f1f1' : '#ffffff'"
                :key="message._id"
                :style="$vuetify.breakpoint.smAndUp ? 'border-top: 1px solid #e2e2e2' : ''"
              >
                <v-row class="px-3">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    lg="3"
                    xl="3"
                    class="py-1 px-0"
                    :style="$vuetify.breakpoint.xsOnly ? 'border-top: 1px solid #e2e2e2' : ''"
                  >
                    <v-list-item three-line>
                      <v-list-item-avatar>
                        <v-img :src="getAvatarUrl()" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <div v-if="message.userKey === null" class="mb-5 author">
                          {{ subjectName }}
                          <br />
                          <span class="timestamp">
                            {{ displayDate(message.timestamp) }}
                            <br />
                            {{ displayTime(message.timestamp) }}
                            <br />
                          </span>
                        </div>
                        <div v-else class="mb-5 author">
                          Case-Manager
                          <br />
                          <span class="timestamp">
                            {{ displayDate(message.timestamp) }}
                            <br />
                            {{ displayTime(message.timestamp) }}
                            <br />
                          </span>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="7" xl="7">
                    <span
                      v-if="index === (messages.length - 1)"
                      class="primary--text font-weight-medium"
                    >{{ messageCreated }}</span>
                    <span
                      :class="[message.userKey === null ? 'primary--text' : '', 'preserve message']"
                    >{{ message.message }}</span>
                    <div
                      v-if="message.attachments && message.attachments.length > 0"
                      class="message primary--text mt-3"
                    >
                      <span class="font-weight-medium">{{ $t('labelAttachments') }}</span>
                      <v-spacer />
                      <span v-for="attachment in message.attachments" v-bind:key="attachment.id" class="mr-2">
                        <v-icon
                          :color="(!attachment.virusScanResult || attachment.virusScanResult.status === 'Clean') ? 'primary' : 'red lighten-2'"
                        >attachment</v-icon>
                        <span
                          v-if="!attachment.virusScanResult || attachment.virusScanResult.status === 'Clean'"
                          class="primary--text"
                        >{{attachment.fileName}}</span>
                        <span
                          v-if="attachment.virusScanResult && attachment.virusScanResult.status !== 'Clean'"
                          class="red--text"
                        >{{attachment.fileName}} (Virus detected)</span>
                      </span>
                    </div>
                  </v-col>
                  <v-col v-if="index === (messages.length - 1)" cols="2" align="right">
                    <v-btn
                      :x-small="$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.mdAndUp"
                      outlined
                      color="primary"
                      @click="caseDetailsDialog = !caseDetailsDialog"
                    >{{ $t('labelViewCaseDetails') }}</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-0" :style="$vuetify.breakpoint.smAndDown ? 'width: 95vw' : 'width: 65vw'">
      <v-form ref="replyForm">
        <v-card class="pb-3 px-2" flat tile>
          <v-card-text>
            <v-row class="px-3">
              <v-col cols="7" class="pb-0 mb-0">
                <v-row>
                  <v-avatar size="32" class="mx-3">
                    <v-img src="/img/dummy.png" />
                  </v-avatar>
                  <v-textarea
                    no-resize
                    v-model="replyMessage.message"
                    outlined
                    rows="5"
                    hide-details
                    :rules="messageRules"
                    :disabled="sending"
                  ></v-textarea>
                </v-row>
              </v-col>
              <v-col cols="5" class="pb-0 mb-0">
                <v-file-input
                  v-model="files"
                  color="primary"
                  class="small-input"
                  dense
                  counter
                  :label="$t('labelAttachments')"
                  multiple
                  :placeholder="$t('hintAttachments')"
                  prepend-icon="attachment"
                  outlined
                  :show-size="1000"
                  :rules="fileRules"
                  :disabled="sending"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 5"
                      :color="fileSize(index) <= 20 && fileAllowed(index)  ? 'primary' : 'error'"
                      dark
                      label
                      small
                      close
                      close-icon="delete_outline"
                      @click:close="removeAttachment(index)"
                    >{{ text }}</v-chip>
                  </template>
                </v-file-input>

                <v-row class="justify-end">
                  <v-col class="d-flex fill-height">
                    <v-btn
                      absolute
                      depressed
                      style="bottom: 15px; right: 40px"
                      :x-small="$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.mdAndUp"
                      :loading="sending"
                      :disabled="sending"
                      color="primary"
                      @click="sendMessage"
                    >{{ $t('labelSendMessage') }}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </div>
    <v-dialog v-model="caseDetailsDialog" max-width="750">
      <case-details-dialog
        :currentCase="currentCase"
        @close="caseDetailsDialog = !caseDetailsDialog"
      ></case-details-dialog>
    </v-dialog>
    <v-dialog v-model="passwordDialog" width="unset" persistent>
      <v-card class="pa-3" raised>
        <v-card-title>{{ $t('titleCasePassword') }}</v-card-title>
        <v-card-text>
          <v-row align="center">
            <v-col cols="4">{{ $t('labelPassword') }}:</v-col>
            <v-col cols="8">
              <v-text-field
                v-model="password"
                type="password"
                :label="$t('labelPassword')"
                :hint="$t('hintPassword')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="pa-0">
            <v-col class="px-5">
              <div class="d-flex flex-row justify-center" style="position: relative;">
                <v-btn
                  small
                  outlined
                  style="position: absolute; left: 0"
                  color="primary"
                  @click="back"
                >{{ $t('labelBack') }}</v-btn>
                <v-btn
                  small
                  depressed
                  color="primary"
                  @click="loadWithPassword"
                >{{ $t('labelSubmit') }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import CaseDetailsDialog from "@/components/CaseDetailsDialog.vue";
import moment from "moment";

export default {
  name: "Setup",
  components: {
    CaseDetailsDialog,
  },
  data: () => ({
    caseDetailsDialog: false,
    clientId: null,
    caseKey: null,
    files: [],
    replyDialog: false,
    replyMessage: { caseKey: "", message: "" },
    passwordDialog: false,
    password: null,
    sending: false,
  }),
  methods: {
    ...mapMutations(["setLoading"]),
    ...mapActions([
      "destroyAuthData",
      "getClient",
      "getCase",
      "getFaqLibrary",
      "getMessages",
      "createCaseMessage",
      "createMessageAttachment",
    ]),
    async initData() {
      this.setLoading(true);
      let requests = [];
      let dto = {
        clientIdentifier: this.clientId,
        caseKey: this.caseKey,
        password: this.password,
      };

      if (!this.client._key) requests.push(this.getClient(this.clientId));
      if (!this.faqLibrary.entries) requests.push(this.getFaqLibrary());
      requests.push(this.getCase(dto));
      requests.push(this.getMessages(dto));

      await Promise.all(requests)
        .then((response) => {
          this.setLoading(false);
          this.registerOnCloseHandler();
          this.$nextTick(() => {
            this.$refs.messageHistory.scrollTop = 0;
          });
        })
        .catch((error) => {
          this.setLoading(false);
          if (error.response.status === 403) {
            this.passwordDialog = true;
          } else {
            this.$bus.$emit("errorMessage", this.$t("errorCaseDoesNotExist"));
            this.$router.push({path: "/"});
          }
        });
    },
    async sendMessage() {
      if (this.$refs.replyForm.validate() !== true) return;

      this.sending = true;
      this.setLoading(true);
      let dto = {
        clientIdentifier: this.clientId,
        caseKey: this.caseKey,
        message: this.replyMessage,
      };

      try {
        let result = await this.createCaseMessage(dto);

        if (this.files.length > 0) {
          let message = result.data.data;
          await this.sendAttachments(message);
        }

        this.replyDialog = false;
        this.replyMessage.message = "";

        this.$nextTick(() => this.$refs.replyForm.resetValidation());
        await this.initData();
      } catch (error) {
        if (error.response.status === 403) {
          this.$bus.$emit("errorMessage", "Your session has ended");
          await this.$router.push({path: "/"});
        } else {
          this.$bus.$emit("errorMessage", "An unknown error occurred");
        }
      } finally {
        this.files = [];
        this.setLoading(false);
        this.sending = false;
      }
    },
    async sendAttachments(message) {
      let requests = [];

      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];

        try {
          let attachmentDTO = {
            clientIdentifier: this.clientId,
            caseKey: this.caseKey,
            messageKey: message._key,
            file: file,
          };

          requests.push(this.createMessageAttachment(attachmentDTO));
        } catch (err) {
          this.$bus.$emit("errorMessage", "An unknown error occurred");
        }
      }

      await Promise.all(requests);
    },
    async loadWithPassword() {
      this.setLoading(true);

      let dto = {
        clientIdentifier: this.clientId,
        caseKey: this.caseKey,
        password: this.password,
      };

      try {
        await this.getCase(dto);
        await this.getMessages(dto);

        this.$nextTick(() => {
          this.$refs.messageHistory.scrollTop = 0;
        });

        this.passwordDialog = false;
      } catch (error) {
        this.$bus.$emit("errorMessage", this.$t("errorWrongPassword"));
      } finally {
        this.password = null;
        this.setLoading(false);
      }
    },
    displayDate(timestamp) {
      let time = moment(timestamp);
      return time.format("DD.MM.YYYY");
    },
    displayTime(timestamp) {
      let time = moment(timestamp);
      return time.format("hh:mm");
    },
    removeAttachment(index) {
      this.files.splice(index, 1);
    },
    fileSize(index) {
      return this.files[index].size / 1000 / 1000;
    },
    fileAllowed(index) {
      const extension = this.files[index].name.split('.').pop();
      return this.extensionAllowed(extension);
    },
    extensionAllowed(extension) {
      const forbidden = ['zip', 'tar', 'gz', 'bz2', 'xv', 'iso', '7z', 'rar'];

      if(forbidden.includes(extension))
        return false;
      
      if(this.configuration.fileTypeRestriction && this.configuration.fileTypeRestriction.whiteList) {
        return this.configuration.fileTypeRestriction.whiteList.includes(extension);
      } else if(this.configuration.fileTypeRestriction && this.configuration.fileTypeRestriction.blackList) {
        return !this.configuration.fileTypeRestriction.blackList.includes(extension);
      } else {
        return true;
      }
    },
    getAvatarUrl() {
      return `/avatars/dummy.png`;
    },
    back() {
      this.$router.push({
        name: "client",
        params: { clientId: this.clientId },
      });
    },
    registerOnCloseHandler() {
      window.addEventListener("beforeunload", (e) => {
        this.handleUnload(e);
      });
    },
    async handleUnload(e) {
      await this.destroyAuthData();
    },
  },
  computed: {
    ...mapGetters(["operatorName"]),
    ...mapState(["client", "configuration", "currentCase", "faqLibrary", "messages", "loading"]),
    caseTitle() {
      if (!this.currentCase) return "N/A";
      else return this.currentCase._key;
    },
    fileRules() {
      return [
        (v) => !!(v.length < 6) || this.$t("errorTooManyFiles"),
        (v) => {
          const t = v.filter((x) => x.size / 1000 / 1000 > 20.9);

          if (t.length > 0) return this.$t("errorFileTooLarge");

          return true;
        },
        (v) => {
          var t = v.filter((x) => {
            const extension = x.name.split('.').pop();
            return !this.extensionAllowed(extension);
          })

          if(t.length > 0) return "File type not allowed";

          return true;
        }
      ];
    },
    messageRules() {
      return [(v) => !!v || this.$t("errorFieldRequired")];
    },
    subjectName() {
      if (!this.currentCase || !this.currentCase.metaData) return "N/A";

      let meta = this.currentCase.metaData;

      if (meta.firstName) return `${meta.firstName} ${meta.lastName}`;
      else return `${meta.nickName}`;
    },
    messageCreated() {
      if (!this.currentCase || !this.currentCase.metaData) return "N/A";

      let meta = this.currentCase.metaData;

      if (meta.firstName) return this.$t("labelPersonalCaseCreated");
      else return this.$t("labelAnonymousCaseCreated");
    },
  },
  mounted() {
    this.clientId = this.$route.params.clientId;
    this.caseKey = this.$route.params.caseKey;
    this.initData();
  },
};
</script>

<style scoped>
.preserve {
  white-space: pre-line;
}

.message {
  display: block;
  font-size: 0.85rem;
  line-height: 1.25rem;
}

.case {
  border: 1px solid green;
}

.authro {
  font-size: 1rem;
}

.timestamp {
  font-size: 0.7rem;
}

.message-history {
  overflow: auto !important;

  padding: 10px;
  scrollbar-color: #e0e0e0 #fff;

  -ms-overflow-style: auto;
  scrollbar-base-color: #e0e0e0;
  scrollbar-face-color: #e0e0e0;
  scrollbar-3dlight-color: #fff;
  scrollbar-highlight-color: #fff;
  scrollbar-track-color: #fff;
  scrollbar-arrow-color: #e0e0e0;
  scrollbar-shadow-color: #fff;
  scrollbar-dark-shadow-color: #fff;

  display: flex;
  /* TODO: Revert once Firefox 81 is out for a while */
  /* Broken until Firefox 81*/
  /*flex-direction: column-reverse;*/
  /*height: 100%;*/
  flex-direction: column;
  max-height: 100%;
}

/*
.message-history::-webkit-scrollbar {
  width: 10px;
}
*/

.message-history::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 25px rgba(0, 69, 77, 1);
}

.message-history::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 25px rgba(224, 224, 224, 1);
}

.container {
  height: 95%;
  /* TODO: Remove once Firefox 81 is out for a while */
  /* Broken until Firefox 81*/
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;
}
</style>