<template>
  <div style="height: 60vh; width: 100vw">
    <v-main align="center" class="fill-height mt-0 pt-5" row wrap style="width: 100%">
      <v-row v-if="!loading" justify="start" align="center" class="fill-height">
        <v-col
            cols="10"
            offset="1"
            sm="8"
            offset-sm="2"
            md="6"
            offset-md="3"
            lg="6"
            offset-lg="3"
            class="pa-0"
        >
          <div v-if="isoCode === 'lu'">
            <span class="canary-title white--text" style="width: 100%">
              <span v-if="$i18n.locale=='de'">
                Schritt 2: Empfänger der Hinweismeldung
              </span>
              <span v-else>
                Step 2: Please select the addressee of your whistleblowing report
              </span>
            </span><br/>
            <span class="white--text" style="width: 100%">
              ({{ localOffice }})
            </span>
            <p v-if="$i18n.locale=='de'" class="text-left white--text mt-10" style="width: 100%">
              <span class="font-weight-bold">Option 1:</span><br/>
                Ihre Meldung wird an eine dafür zuständige
              <span class="font-weight-bold">
                CRO-Stelle am Hauptsitz in der Schweiz und die CRO-Ermittlungsstelle
              </span>
              der Bank Julius Baer Europa S.A weitergeleitet. Die Angelegenheit wird vom Schweizer Hauptsitz und/oder in Luxemburg
              durch die CRO-Ermittlungsstelle Bank Julius Baer Europa S.A. überprüft.
            </p>
            <p v-else class="text-left white--text mt-10" style="width: 100%">
              <span class="font-weight-bold">Option 1:</span><br/>
              Your report will be forwarded to a designated
              <span class="font-weight-bold">
                CRO investigation function at the head office in Switzerland and the CRO
              </span>
              at {{ localOffice }}. The matter will be investigated by the head office
              in Switzerland and/or the CRO investigation function at {{ localOffice }} in {{ localCountry }}.
            </p>
            <p v-if="$i18n.locale=='de'" class="text-left white--text mt-10" style="width: 100%">
              <span class="font-weight-bold">Option 2:</span><br/>
              Ihre Meldung wird ausschließlich an die
              <span class="font-weight-bold">
                CRO-Ermittlungsstelle der Bank Julius Baer Europa S.A.
              </span>
              weitergeleitet.
              Auch die Untersuchung wird ausschließlich durch die CRO-Ermittlungsstelle in Luxemburg geleitet,
              sofern sich die gemeldete Angelegenheit nicht auf außerhalb Ihrer Gesellschaft liegendeb
              Sachverhalte bezieht.
            </p>
            <p v-else class="text-left white--text mt-10" style="width: 100%">
              <span class="font-weight-bold">Option 2:</span><br/>
              Your report will be forwarded exclusively to the
              <span class="font-weight-bold">
                CRO investigation function at {{ localOffice }}.
              </span>
              Also the investigation will be managed by
              the CRO investigation function in {{ localCountry }}, unless the reported matter pertains to issues
              going beyond {{ localOffice }}.
            </p>
          </div>
          <div v-else>
            <span class="canary-title white--text" style="width: 100%">
              <span v-if="$i18n.locale=='de'">
                Schritt 2: Empfänger der Hinweismeldung
              </span>
              <span v-else>
                Step 2: Addressee of whistleblowing report
              </span>
            </span><br/>
            <span class="white--text" style="width: 100%">
              ({{ localOffice }})
            </span>
            <p v-if="$i18n.locale=='de'" class="text-left white--text mt-10" style="width: 100%">
              Hinweis: Ihre Meldung wird an eine dafür zuständige
              <span class="font-weight-bold">
                CRO-Ermittlungsstelle am Hauptsitz in der Schweiz und an eine lokale Ermittlungsstelle
              </span>
              weitergeleitet. Die Angelegenheit wird vom Schweizer Hauptsitz und/oder einer lokalen Stelle Ihrer
              Gesellschaft überprüft.
            </p>
            <p v-else class="text-left white--text mt-10" style="width: 100%">
              Please note that your report will be forwarded to a designated
              <span class="font-weight-bold">
                CRO investigation function at the head office in Switzerland and a local investigation function.
              </span>
              The matter will be investigated by the head office in Switzerland and/or a local function of your entity.
            </p>
            <p v-if="$i18n.locale=='de'" class="text-left white--text mt-10" style="width: 100%">
              Sollten Sie mit den obigen Vorgaben nicht einverstanden sein, wird Ihre Meldung ausschließlich an eine
              dafür zuständige
              <span class="font-weight-bold">
                lokale Ermittlungsstelle innerhalb Ihrer Gesellschaft
              </span>
              weitergeleitet. Auch die Untersuchung wird ausschließlich von der lokalen Ermittlungsstelle geleitet,
              sofern sich die gemeldete Angelegenheit nicht auf außerhalb Ihrer Gesellschaft liegenden Sachverhalte
              bezieht.
            </p>
            <p v-else class="text-left white--text mt-10" style="width: 100%">
              In case you disagree with the above, your report will be forwarded to a designated
              <span class="font-weight-bold">
                local investigation function of your entity only.
              </span>
              Also the investigation will be managed by the local investigation function exclusively,
              unless the reported matter relates to issues going beyond your entity.
            </p>
          </div>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col cols="10" offset="1" lg="8" offset-lg="2" >
          <v-row justify="center" align="center">
            <v-col cols="10" sm="4" md="4" class="py-0" >
              <v-col
                  cols="12"
                  class="pa-0"
                  v-if="isoCode==='lu'"
              >
                <v-card width="100%" class="fill-height d-flex flex-column py-6" flat tile ref="card1">
                  <v-card-title class="justify-center primary--text text-center font-weight-bold">
                    Option 1
                  </v-card-title>
                  <v-card-text class="primary--text">
                    <span v-if="$i18n.locale=='de'">
                      (CRO-Stelle am Schweizer Hauptsitz und CRO-Ermittlungsstelle der Bank Julius Baer Europa S.A.)<br/>
                    </span>
                    <span v-else>
                      (CRO function at head office in Switzerland and the CRO investigation function of {{ localOffice }})
                    </span>
                  </v-card-text>
                  <v-spacer></v-spacer>
                  <v-card-text class="justify-center d-flex pb-0">
                    <v-btn small depressed color="primary" to="/lu-global">{{ $t('labelContinue') }}</v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                  cols="12"
                  class="pa-0"
                  v-else
              >
                <v-card width="100%" class="fill-height d-flex flex-column py-6" flat tile>
                  <v-card-title class="justify-center primary--text text-center font-weight-bold">
                    <span v-if="$i18n.locale=='de'">
                      Ich stimme zu
                    </span>
                    <span v-else>
                      I agree
                    </span>
                  </v-card-title>
                  <v-spacer></v-spacer>
                  <v-card-text class="justify-center d-flex pb-0">
                    <v-btn small depressed color="primary" :to="`/${isoCode}-global`">{{ $t('labelContinue') }}</v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-col>
            <v-col cols="10" sm="4" md="4" class="py-0 fill-height">
              <v-col
                  cols="12"
                  class="pa-0"
                  v-if="isoCode==='lu'"
              >
                <v-card width="100%" :height="card1Height" class="fill-height d-flex flex-column py-6" flat tile>
                  <v-card-title class="justify-center primary--text text-center font-weight-bold">
                    Option 2
                  </v-card-title>
                  <v-card-text class="primary--text">
                    <span v-if="$i18n.locale=='de'">
                      (CRO-Ermittlungsstelle der Bank Julius Baer Europa S.A.)
                    </span>
                    <span v-else>
                      (The CRO investigation function at {{ localOffice }})
                    </span>
                  </v-card-text>
                  <v-spacer></v-spacer>
                  <v-card-text class="justify-center d-flex pb-0">
                    <v-btn small depressed color="primary" to="/lu">{{ $t('labelContinue') }}</v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                  cols="12"
                  class="pa-0"
                  v-else
              >
                <v-card width="100%" class="fill-height d-flex flex-column py-6" flat tile>
                  <v-card-title class="justify-center primary--text text-center font-weight-bold">
                    <span v-if="$i18n.locale=='de'">
                      Ich stimme nicht zu
                    </span>
                    <span v-else>
                      I disagree
                    </span>
                  </v-card-title>
                  <v-spacer></v-spacer>
                  <v-card-text class="justify-center d-flex pb-0">
                    <v-btn small depressed color="primary" :to="`/${isoCode}`">{{ $t('labelContinue') }}</v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "Step2",
  data: () => ({
    mounted: false,
    loading: false,
  }),
  methods: {

  },
  computed: {
    card1Height() {
      if(!this.mounted)
        return;

      const h = this.$refs.card1 ? this.$refs.card1.$el.clientHeight : 20;
      return `${h}px`;
    },
    isoCode() {
      return this.$route.params.isocode;
    },
    localCountry() {
      switch(this.$route.params.isocode) {
        case "de":
          return "Germany";
        case "lu":
          return "Luxembourg";
        default:
          return "INVALID";
      }
    },
    localOffice() {
      switch(this.$route.params.isocode) {
        case "de":
          return "Bank Julius Bär Deutschland AG";
        case "lu":
          return "Bank Julius Baer Europe S.A.";
        default:
          return "INVALID";
      }
    },
  },
  mounted() {
    this.mounted = true;
  },
  updated() {
    console.log('t');
  }
}
</script>

<style scoped>
  .home {
    width: 100%;
  }
</style>