<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      clipped-left
      flat
    >
      <v-row>
      <div class="header pt-4 pl-4">
        <img src="/img/logo.svg" class="header" />
      </div>
      <v-spacer/>

      <v-col
        v-if="!this.$route.path.includes('admin')"
        cols="8"
        align="right"
        align-self="center"
        :class="$vuetify.breakpoint.xsOnly? 'mr-5' : ''"
      >
        <v-btn
          v-if="$route.name === 'case'"
          outlined
          color="white"
          :x-small="$vuetify.breakpoint.xsOnly"
          :small="$vuetify.breakpoint.smAndUp"
          class="mr-3"
          @click="confirmLogout"
        >
          {{ $t('labelLogout') }}
        </v-btn>
        <a href="javascript:void(0)" @click="faqDialog = true" class="help font-weight-medium">
          {{ $t('labelHelp') }}
        </a>
        <v-skeleton-loader
          v-if="loading && (!availableLanguages || availableLanguages.length == 0)"
          type="text"
          light
        ></v-skeleton-loader>
        <v-icon
            :small="$vuetify.breakpoint.xsOnly"
            class="ml-4 mr-1"
        >
          language
        </v-icon>
        <v-select
            class="langselect d-inline-flex"
            v-model="$i18n.locale"
            :items="availableLanguages"
            item-value="code"
            item-text="code"
            hide-details
            single-line
            dense
            @change="localeChanged()"
        >
        </v-select>
      </v-col>
    </v-row>
    </v-app-bar>

    <v-main class="content">
      <v-container fluid>
        <v-row justify="center" style="padding: 0px 0px;">
          <router-view></router-view>
        </v-row>
      </v-container>
    </v-main>

    <v-footer dark color="primary" class="py-4">
      <v-row justify="center">
        <v-col cols="12" align="center" class="pa-0">
          <a class="font-weight-bold white--text" @click="impressumDialog = true">
            {{ $t('labelImpressum') }}
          </a>
        </v-col>
        <v-col cols="12" align="center" class="pa-0">
          <span class="footer">
            Anonymos ©by <a href="https://www.farsight.net" target="_blank" class="white--text">Farsight AG</a>
          </span>
        </v-col>
      </v-row>
    </v-footer>

    <v-dialog v-model="impressumDialog" persistent fullscreen>
      <impressum @close="impressumDialog = false"></impressum>
    </v-dialog>

    <v-dialog v-model="spinner" persistent width="100px" @keydown="keyhandler">
      <v-container>
        <v-layout align-center justify-center class="mb-2">
          Waiting
        </v-layout>
        <v-layout align-center justify-center>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-dialog v-model="confirmDialog" width="unset" transition="dialog-top-transition" persistent>
      <v-card
        width="unset"
        class="pa-3 confirm-card"
        :min-width="$vuetify.breakpoint.xsOnly ? '320' : '420'"
      >
        <v-card-title class="justify-center">{{ $t('titleConfirmationRequired') }}</v-card-title>
        <v-card-text>{{confirmText}}</v-card-text>
        <v-card-actions>
          <v-row class="pa-0">
            <v-col class="pa-0">
              <div class="d-flex flex-row justify-center" style="position: relative;">
                <v-btn
                  small
                  outlined
                  style="position: absolute; left: 25px"
                  class="text-normal"
                  color="primary"
                  @click.prevent="$store.commit('setConfirmDialog', false)"
                >
                  {{ $t('labelCancel') }}
                </v-btn>
                <v-btn
                  small
                  depressed
                  class="text-normal"
                  color="primary"
                  @click.prevent="executeConfirmAction()"
                >
                  {{ $t('labelConfirm') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="faqDialog" :width="$vuetify.breakpoint.smAndDown ? '100%' : '75%'" max-width="100%">
      <faq-dialog :entries="faqEntries" :clientName="client.name" @close="faqDialog = !faqDialog"></faq-dialog>
    </v-dialog>
    <v-snackbar :timeout="timeout" :color="msgcolor" v-model="snackbar">
      <v-row justify="space-between">
        <span class="pl-5">
          {{ msg }}
        </span>
        <v-btn dark x-small @click.native="snackbar = false" depressed>{{ $t('labelClose') }}</v-btn>
      </v-row>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions,  mapState, mapGetters } from 'vuex'
import FaqDialog from '@/components/FaqDialog.vue';
import Impressum from '@/components/Impressum.vue';

export default {
  name: 'App',
  components: {
    FaqDialog,
    Impressum,
    loading: false,
  },
  data: () => ({
      snackbar: false,
      msg: "",
      msgcolor: "primary",
      timeout: 5500,
      faqDialog: false,
      impressumDialog: false,
  }),
  methods: {
    ...mapActions([
      'destroyAuthData',
      'getConfiguration',
      'getLocales',
      'getFaqLibrary',
      'setLoading',
    ]),
    keyhandler(event) {
      event.preventDefault();
    },
    executeConfirmAction() {
      this.confirmAction();
      this.$store.commit('setConfirmDialog', false);
    },
    localeChanged() {
      localStorage.setItem("locale", this.$i18n.locale);
    },
    confirmLogout() {
      let msg = this.$t("messageConfirmLogout");
      this.$store.commit("setConfirmText", msg);
      this.$store.commit("setConfirmAction", async () => {
        await this.handleUnload(null);
        this.$router.push({ path: `/` });
      });
      this.$store.commit("setConfirmDialog", true);
    },
    async handleUnload(e) {
      await this.destroyAuthData();
    },
  },
  computed: {
    ...mapState([
      'client',
      'confirmAction',
      'confirmText',
      'confirmDialog',
      'configuration',
      'faqLibrary',
      'loading',
      'spinner',
    ]),
    ...mapGetters([
      'availableLanguages',
      'availableLanguagesMap',
    ]),
    faqEntries() {
      if(this.client.faqEntries)
        return [...this.faqLibrary.entries, ...this.client.faqEntries];
      else
        return this.faqLibrary.entries;
    }
  },
  async mounted() {
    this.$bus.$on("errorMessage", event => {
      this.msgcolor = "error";
      this.msg = event;
      this.snackbar = true;
    });

    this.$bus.$on("successMessage", event => {
      this.msgcolor = "primary";
      this.msg = event;
      this.snackbar = true;
    });

    this.setLoading(true);

    let requests = [];
    // Retrieve stored locales and configuration data
    requests.push(this.getLocales());
    requests.push(this.getConfiguration());
    requests.push(this.getFaqLibrary());


    window.showHelp = () => { this.faqDialog = true; };

    try {
      let results = await Promise.all(requests);
      let locales = results[0].data.data;

      locales.forEach(locale => {
        this.$i18n.setLocaleMessage(locale.code, locale.messages);
      });

      // Retrieve locale from localStorage
      let storedLocale = localStorage.getItem("locale");

      // If not set or set to an unvailable language,...default to default language
      if(!storedLocale || !this.availableLanguagesMap.hasOwnProperty(storedLocale)) {
        this.$i18n.locale = this.configuration.defaultLanguage.code;
      } else {
        this.$i18n.locale = storedLocale;
      }

    } catch(error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  },
};
</script>


<style lang="scss">
html {
  overflow: hidden !important;
}

.content {
  overflow-y: auto; 
  overflow-x: hidden;
}

@media (min-width: 300px){
  .header {
     width: auto;
     height: 30px;
     margin-left: 0px;
   }
}

@media (min-width: 950px){
  .header {
     width: auto;
     height: 42px;
     margin-left: 10px;
   }
}

.canary {
  position: relative;
  left: -20px;
  top: 5px;
  height: 38px;
}

.footer {
  font-size: 10pt;
}

.v-btn {
  text-transform:none !important;
  padding: 0 15px !important;
}

@media all and (min-width: 300px) {
  html {
    font-size: 10px !important;
  }
}

@media all and (min-width: 375px) {
  html {
    font-size: 14px !important;
  }
}

@media all and (min-width: 768px) {
  html {
    font-size: 15px !important;

  }
}

@media all and (min-width: 1025px) {
  html {
    font-size: 16px !important;
  }
}

@media all and (min-width: 300px){
  .langselect {
    font-size: 1em !important;
    width: 50px;
  }
}

@media all and (min-width: 1200px){
  .langselect {
    font-size: 1em !important;
    width: 55px;
  }
}

@media all and (min-width: 300px){
  .help {
    color: #fff !important;
    font-size: 1em;
    text-decoration: none;
  }
}

@media (min-width: 1200px){
  .help {
    color: #fff !important;
    font-size: 1em;
    text-decoration: none;
  }
}

.langselect.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.langselect.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}

.v-file-input__text--placeholder {
  font-size: 0.75rem !important;
}

.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.small-input {
  font-size: 1em !important;
}

.v-messages__message {
  color: #717C7D;
}

.canary-title {
  font-size: 1.5rem;
}
</style>
