<template>
  <div>
    <v-card class="pa-3" flat tile>
      <v-card-title>
        {{ $t("titleFaq") }}
        <v-btn icon @click="close" class="dialog-close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels accordion hover v-model="currentPanel">
          <v-expansion-panel v-for="(item, index) in faqsWithText" :key="index">
            <v-expansion-panel-header
              :class="[index === currentPanel ? 'mb-5' : '']"
            >
              <span v-html="getFaqTitle(index, item)"></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span v-html="getFaqText(item)"></span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "FAQDialog",
  data: () => ({
    currentPanel: null,
  }),
  props: ["clientName", "entries"],
  computed: {
    ...mapState(["configuration"]),
    faqsWithText() {
      let faqs = this.entries.filter(
        (x) =>
          x.title.hasOwnProperty(this.currentLocale) &&
          x.text.hasOwnProperty(this.currentLocale)
      );

      return faqs;
    },
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getFaqTitle(index, item) {
      if (
        !item.title[this.currentLocale] ||
        item.title[this.currentLocale].length === 0
      )
        return "";

      let text = item.title[this.currentLocale].replace(
        /{client}/g,
        this.clientName
      );
      text = text.replace(/{operatorName}/g, this.configuration.operatorName);

      return `${index + 1}. ${text}`;
    },
    getFaqText(item) {
      if (
        !item.text[this.currentLocale] ||
        item.text[this.currentLocale].length === 0
      )
        return "";

      let text = item.text[this.currentLocale].replace(
        /{client}/g,
        this.clientName
      );
      text = text.replace(/{operatorName}/g, this.configuration.operatorName);

      return text;
    },
  },
  async mounted() {},
};
</script>

<style scoped>
.v-expansion-panel-header--active {
  background-color: rgba(176,170,126,.15)
}
</style>