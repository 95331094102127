import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify';
import 'typeface-rubik/index.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './assets/fonts/hco_fonts.css'
import i18n from './i18n'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

axios.defaults.withCredentials = true;
axios.defaults.headers = { 'X-Requested-With': 'XmlHttpRequest' };

var bus = new Vue({
});
Vue.prototype.$bus = bus;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
