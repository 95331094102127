<template>
  <div class="home">
    <v-main align="center" class="fill-height mt-0 pt-5" row wrap style="width: 100%">
      <v-row v-if="!loading" justify="start" align="center">
        <v-col
          cols="10"
          offset="1"
          sm="8"
          offset-sm="2"
          md="6"
          offset-md="3"
          lg="6"
          offset-lg="3"
          class="pa-0"
        >
          <v-card class="pa-0" flat tile color="primary" width="100%">
            <span class="white--text">
              <v-card-title
                class="justify-center canary-title"
              >
                Welcome to Julius Baer’s Whistleblowing Platform
              </v-card-title>
            </span>
            <v-card-text
              class="white--text text-left text-default"
              v-html="$t('messageWelcome', { operatorName: operatorName })"
            ></v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
      <v-row>
        <v-col cols="10" offset="1" lg="8" offset-lg="2">
          <v-row justify="center" align="center" class="fill-height">
            <v-col cols="10" sm="4" md="4" class="py-0">
              <v-col
                  cols="12"
              >
                <v-card v-if="$i18n.locale=='de'" width="100%" class="px-3 d-flex flex-column py-0" flat tile>
                  <v-card-title class="text-left primary--text card-text font-weight-bold">
                    Neue Hinweismeldung
                  </v-card-title>
                  <v-card-text class="text-left primary--text card-text">
                    <p>Das Abschicken einer Meldung auf dieser Hinweisgeber-Plattform erfordert die folgenden sechs Schritte:</p>
                    <ol>
                      <li>Auswahl der Gesellschaft der JB-Gruppe</li>
                      <li>Auswahl des Empfängers</li>
                      <li>Kenntnisnahme und Bestätigung der Datenschutzerklärung</li>
                      <li>Anonyme Meldung vs. Offenlegung der Identität</li>
                      <li>Ausführlicher Bericht</li>
                      <li>Abschicken der Meldung</li>
                    </ol>
                  </v-card-text>
                  <v-card-text class="justify-center d-flex pb-5">
                    <v-btn small depressed color="primary" to="/group-selection">{{ $t('labelContinue') }}</v-btn>
                  </v-card-text>
                </v-card>
                <v-card v-else width="100%" class="px-3 d-flex flex-column py-0" flat tile>
                  <v-card-title class="text-left primary--text card-text font-weight-bold">
                    Filing of a new whistleblowing report
                  </v-card-title>
                  <v-card-text class="text-left primary--text card-text">
                    <p>The filing of a report on this Whistleblowing Platform consists of the following 6 steps:</p>
                    <ol>
                      <li>Selection of JB Group entity</li>
                      <li>Selection of addressee</li>
                      <li>Acknowledgment and confirmation of Privacy Statement</li>
                      <li>Selection of anonymous filing vs. disclosure of identity</li>
                      <li>Detailed report</li>
                      <li>Submission</li>
                    </ol>
                  </v-card-text>
                  <v-card-text class="justify-center d-flex pb-5">
                    <v-btn small depressed color="primary" to="/group-selection">{{ $t('labelContinue') }}</v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-col>
            <v-col cols="10" sm="4" md="4" class="py-0 fill-height" >
              <v-col
                  cols="12"
                  class="py-0 fill-height"
              >
                <v-card v-if="$i18n.locale=='de'" width="100%" class="px-3 d-flex flex-column py-0 fill-height" flat tile>
                  <v-card-title class="justify-center primary--text font-weight-bold">{{ $t('titleExistingCase') }} (für bereits abgeschickte Meldungen)</v-card-title>
                  <v-row class="align-center" style="height: 60%">
                    <v-card-text class="primary--text card-text">
                      <v-text-field v-model="caseKey" :label="$t('labelCaseNumber')"></v-text-field>
                    </v-card-text>
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-card-text class="justify-center d-flex pb-5">
                    <v-btn small depressed color="primary" @click="viewCase">{{ $t('labelContinue') }}</v-btn>
                  </v-card-text>
                </v-card>
                <v-card v-else width="100%" class="px-3 d-flex flex-column py-0 fill-height" flat tile>
                  <v-card-title class="justify-center primary--text font-weight-bold">{{ $t('titleExistingCase') }} (for previously filed reports)</v-card-title>
                  <v-row class="align-center" style="height: 60%">
                    <v-card-text class="primary--text card-text">
                      <v-text-field v-model="caseKey" :label="$t('labelCaseNumber')"></v-text-field>
                    </v-card-text>
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-card-text class="justify-center d-flex pb-5">
                    <v-btn small depressed color="primary" @click="viewCase">{{ $t('labelContinue') }}</v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  data: () => ({
    caseKey: null,
  }),
  computed: {
    ...mapGetters(["operatorName"]),
    ...mapState(["loading"]),
  },
  methods: {
    ...mapActions([
      "getCase",
    ]),
    async viewCase() {
      let dto = {
        clientIdentifier: '000000',
        caseKey: this.caseKey,
      };

      try {
        await this.getCase(dto);
      } catch(e) {
        const response = e.response;
        if (response.status === 404) {
          this.$bus.$emit("errorMessage", this.$t("errorCaseDoesNotExist"));
        } else {
          await this.$router.push({
            name: "case",
            params: {clientId: 'global', caseKey: this.caseKey},
          });
        }
      }
    },
  }
};
</script>

<style scoped>
.home {
  height: 80vh;
}
</style>